import React, { useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  height: '100%',
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 5,
  borderColor: '#374151',
  borderStyle: "dashed",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: 'pointer',
  text: 'center',
};

const activeStyle = {
  borderColor: "#1E3051"
};

const acceptStyle = {
  borderColor: "#101827"
};

const rejectStyle = {
  borderColor: "#659B5E"
};

function DragDropinput(props) {
  const [files, setFiles] = useState({});

  useEffect(() => {
    function passFife(file) {
      props.getFile(file);
    }
    passFife(files);
},[files])

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    onDrop:
    (acceptedFiles) => {
      setFiles((prevFiles) =>
        acceptedFiles.reduce(
          (acc, file) => ({
            ...acc,
            [file.name]: {
              file,
              fileType: ""
            }
          }),
          prevFiles
        )
      );
    },
    
    accept: ".docx,.pdf"
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const acceptedFileItems = Object.keys(files).map((fileName) => {
    const currentFile = files[fileName].file;
    return (
        <p className="m-0 p-0">{currentFile.path}</p>
    );

  });

  return (
    <div className="control-drag-n-drop">
      <div
      {...getRootProps({ style })}
      >
        <input {...getInputProps()} 
          required
        />
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
          <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
        </svg>
        <p className="m-0 pt-3">Drop file or click here to select file</p>
          {acceptedFileItems}
      </div>
    </div>
  );
}

export default DragDropinput;