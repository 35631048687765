import React, { Component }  from 'react'

export default class Footer extends Component {
    render(){
        return(
            <div className="container" data-aos="fade-in" data-aos-duration="1300">
                <div className="row m-0 g-0 justify-content-center">
                    <div className="col-lg-6 text-center pb-4">
                            <a href="#hero" className="back-to-top">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="row m-0 g-0 justify-content-center">
                    <div className="col-lg-6">
                        <div className="text-center" style={{ marginBottom: '2%' }} >
                            <span className="text-lg text-white"> { this.props.companyDetails.phone_number } </span>
                        </div>
                        <div className="text-center" style={{ marginBottom: '4%' }} >
                            <a className="text-lg text-white" href= {`mailto:${this.props.companyDetails.email}?subject=Mail from OnSight webpage.`} style={{ textDecoration: 'none' }} > { this.props.companyDetails.email } </a>
                        </div>
                        <div className="copyright text-center mt-3">
                            <p className="text-lg text-white">&#169; 2021 OnSight. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
