import React, { Component }  from "react";
import TeamCardWorkWithUsForm from "./TeamWorkWithUsForm";

export default class TeamCardWorkWithUs extends Component {
    render(){
        return( 
            <div className="col-lg-3 col-md-3 col-sm-12 mx-4 py-5 h-100 d-flex justify-content-center">
                <div id="team_card" className="team-card" data-testid="TeamCard"
                    onMouseEnter={ () => this.props.onMouseEnterTeamCard(this.props.id)} 
                    onMouseLeave={ () => this.props.onMouseLeaveTeamCard(this.props.id)}
                >
                    <div className="row m-0 g-0 d-flex justify-content-center align-items-center team-wrapper">
                        {
                            this.props.hovered[this.props.id].hovered ?
                            <div id={ this.props.color } className="emoji-background-hovered"></div>
                            :
                            <div id={ this.props.color } className="emoji-background"></div>

                        }
                        <div id="team_img" className="team-img img-fluid" data-testid="teamPicture" 
                            style={{ 
                                backgroundImage: "url(" + this.props.picture + ")",
                                backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center",
                                position: "relative", zIndex: 100,
                            }}>
                        </div>
                    </div>
                    
                    <div className="row m-0 g-0 mb-4 card-content">
                        <h1 className="text-team-member-card-name" style={{cursor:'pointer'}} data-toggle="modal" data-target="#exampleModalCenter"><b><u>{ this.props.full_name }</u></b> </h1>
                        <div className="text-team-member-card-position mb-2"> { this.props.position }{"\n"}</div>
                    </div>

                    <TeamCardWorkWithUsForm />

                </div>
            </div>
        )
    }
}