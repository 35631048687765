import {
    GET_COMPANY_DETAILS,
    GET_PORTFOLIO,
    GET_TECHSTACK,
    GET_TEAM_MEMBERS,
    PORTFOLIO_HOVER_SHOW,
    PORTFOLIO_HOVER_HIDE,
    CHANGE_ACTIVE_CLASS,
    TEAM_CARD_HOVER_SHOW,
    TEAM_CARD_HOVER_HIDE
}  from '../actions/actionTypes'
import update from 'react-addons-update';


const initialState = {
    companyDetails: {},
    portfolio: [],
    techstack: [],
    teamMembers: [],
    portfolioVisible:[
        {"id": 0, visible: false},
        {"id": 1, visible: false},
        {"id": 2, visible: false},
        {"id": 3, visible: false},
        {"id": 4, visible: false},
        {"id": 5, visible: false},
        {"id": 6, visible: false},
        {"id": 6, visible: false},
    ],
    whatWeDoActiveClass: 1,
    teamMemberHovered:[
        {"id": 0, hovered: false},
        {"id": 1, hovered: false},
        {"id": 2, hovered: false},
        {"id": 3, hovered: false},
        {"id": 4, hovered: false},
        {"id": 5, hovered: false},
        {"id": 6, hovered: false},
    ],

}

export default function reducer(state = initialState, action) {

switch (action.type) {
    case GET_COMPANY_DETAILS:
        return {...state, companyDetails:action.payload}

    case GET_PORTFOLIO:
        return {...state, portfolio:action.payload}

    case GET_TECHSTACK:
        return {...state, techstack:action.payload}

    case GET_TEAM_MEMBERS:
        return {...state, teamMembers:action.payload}

    case PORTFOLIO_HOVER_SHOW:
        return update(state, {portfolioVisible:{
            [action.payload]: {
                visible: { $set: action.data }
            }
        }});

    case PORTFOLIO_HOVER_HIDE:
        return update(state, {portfolioVisible:{
            [action.payload]: {
                visible: { $set: action.data }
            }
        }});

    case TEAM_CARD_HOVER_SHOW:
        return update(state, {teamMemberHovered:{
            [action.payload]: {
                hovered: { $set: action.data }
            }
        }});

    case TEAM_CARD_HOVER_HIDE:
        return update(state, {teamMemberHovered:{
            [action.payload]: {
                hovered: { $set: action.data }
            }
        }});

    case CHANGE_ACTIVE_CLASS:
        return {...state, whatWeDoActiveClass:action.payload}

    default: 
    return state
}
}