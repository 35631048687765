import React, { Component }  from 'react'
import DjangoCSRFToken from 'django-react-csrftoken'

export default class ContactUsForm extends Component{
    constructor(){
        super();
        this.state={
            full_name:'',
            email:'',
            subject:'',
            message:''
        }
        this.changeHandler=this.changeHandler.bind(this);
        this.submitForm=this.submitForm.bind(this);
    }

    changeHandler(event){
        this.setState({
            [event.target.name]:event.target.value
        });
    }

    submitForm(){
        fetch('api/post-contact-us/',{
            method:'POST',
            body:JSON.stringify(this.state),
            headers:{
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        .then(response=>response.json());

        this.setState({
            full_name:'',
            email:'',
            subject:'',
            message:''
        });
    }

    render(){
        return (
            <div className="d-flex justify-content-center">
                <form id="contactForm" name="contactForm" className="contactForm px-5 py-5" data-aos="fade-in" data-aos-duration="1300" data-testid='ContactUsForm'>
                        <DjangoCSRFToken />

                        <h1 className="text-title text-white pb-1 mb-1"> Contact us </h1>
                        <p className="text-md text-white pb-3 mb-3" data-testid='ContactUsText'>  {this.props.companyDetails.invitation_text} </p>

                        <div className="form-group">
                            <label className="text-md text-white float-left" htmlFor="fullName">Full Name</label>
                            <input value={this.state.full_name} name="full_name" data-testid='fullName' id="fullName" 
                                onChange={this.changeHandler} type="text" className="control" />
                        </div>
                        <div className="form-group">
                            <label className="text-md text-white float-left" htmlFor="email">Email</label>
                            <input value={this.state.email} name="email" data-testid='email' id='email'
                                onChange={this.changeHandler} type="email" className="control" />
                        </div>
                        <div className="form-group">                            
                            <label className="text-md text-white float-left" htmlFor="subject">Subject</label>
                            <input value={this.state.subject} name="subject" data-testid='subject' id='subject'
                                onChange={this.changeHandler} type="text" className="control" />
                        </div>
                        <div className="form-group">
                            <label className="text-md text-white float-left" htmlFor="message">Message</label>
                            <textarea value={this.state.message} name="message" data-testid='message' id='message'
                                onChange={this.changeHandler} type="text" className="control-message" />
                        </div>
                        <div className="btn-container">
                            <button type="submit" onClick={this.submitForm} data-testid='submitButton' className="btn btn-contact w-100">Send Message</button>
                        </div>
                </form>
            </div>
        )
    }
}
