import React, { Component }  from 'react'
import ContactUsForm from './ContactUsForm'
import PropTypes from 'prop-types';


export default class ContactUs extends Component {
    render(){
        return(
            <div className="container-fluid h-100 d-flex justify-content-center flex-column contact-us-wrapper" data-testid='ContactUsBackground'>
                <iframe title="map" width="100%" height="65%" frameBorder="0" marginHeight="0" marginWidth="0" scrolling="no" id="map"
                    // src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Vancuver+()&amp&t=&k;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    // Satellite map variant
                    src="https://maps.google.com/maps?q=vancuver&t=k&z=12&ie=UTF8&iwloc=&output=embed"

                    style={{ filter: 'grayscale(1) contrast(1.2) opacity(0.16)', position:'absolute' }}
                ></iframe>
                
                <div className="row m-0 g-0 align-items-center justify-content-end h-100">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 text-center contact-form-wrapper" >
                        <ContactUsForm companyDetails={this.props.companyDetails} data-testid='ContactUsForm' />
                    </div>
                </div>
            </div>
        )
    }
}

ContactUs.propTypes = {
    companyDetails: PropTypes.object
}