import React, { Component }  from 'react'


export default class PortfolioNonModal extends Component {


    render(){
        return(
            <a href={ this.props.url } style={{textDecoration: 'none'}}>
                <div className={`row h-100 m-0 g-0 card${this.props.cardClass} d-flex text-center justify-content-center align-items-center card-wrapper-animation `} data-testid='cardAfterHover'>
                    <div className="col-10 p-0">
                        {
                            this.props.logo &&
                                <img src={ this.props.logo } alt="logo_small" className="pt-3 small-logo-hovered img-fluid" data-testid='logoAfterHover' />
                        }
                        <p className="portfolio-card-title text-white text-center my-4 animated-content h1"> { this.props.title } </p>
                        <p className="portfolio-card-description portfolio-card-description-color text-center m-0 h6"> { this.props.short_description } </p>
                        <a className ="my-5 btn btn-portfolio-card" href={ this.props.url } role="button">See examples</a>
                    </div>
                </div>
            </a>
        )


}
}