import React, { Component }  from 'react'
import WhatWeDoCard from './WhatWeDoCard'
import PropTypes from 'prop-types';

export default class WhatWeDo extends Component {
    render(){
        return(
            <div className="container-fluid h-100 d-flex flex-column what-we-do-wrapper" data-testid='WhatWeDoComponent'>

                <div className="row what-we-do-wrapper g-0 text-center" >
                    <h1 id="what-we-do" className="text-title my-4 py-4 text-white" style={{ marginBottom: '3%', marginTop: '5%' }} >What we do</h1>
                    <p className="h4 py-3 my-3 px-md-5 px-lg-5 px-xl-5 px-sm-2 text-white" data-testid='aboutText'> { this.props.companyDetails.about_text } </p>
                </div>

                <div className="row py-3 my-3 px-4 g-0 w-100 text-center d-flex justify-content-center" >
                    < WhatWeDoCard techstack={this.props.techstack}  whatWeDoActiveClass = { this.props.whatWeDoActiveClass } changeActiveClass = { this.props.changeActiveClass } />
                </div>

            </div>
        )
    }
}

WhatWeDo.propTypes = {
    companyDetails: PropTypes.object,
    techstack: PropTypes.array,
    whatWeDoActiveClass: PropTypes.number,
    changeActiveClass: PropTypes.func
}