import React, { Component }  from 'react'
import PropTypes from 'prop-types';


export default class WhatWeDoCard extends Component {
    render(){
        return(
            <>
                <div className="row m-0 g-0 card-what-we-do" data-aos="fade-in" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-testid="WhatWeDoCard">
                    <div className="col-5 py-5">
                        <ul className="what-we-do-list">
                            {
                                Object.entries(this.props.techstack).map(([key, value]) =>
                                    <li id={ `${value.id}-list-item`}
                                        key={ `${value.id}-list-item`}
                                        onClick={ () => this.props.changeActiveClass(value.id) }
                                    >
                                        <span className="what-we-do-number"> { ('0' + value.id).slice(-2) } </span>
                                        <span className={`text-what-we-do-card ${value.id === this.props.whatWeDoActiveClass ? 'what-we-do-active' : ''}`}>
                                            {value.title}
                                        </span>
                                        

                                    </li>
                                )
                            }
                        </ul>
                    </div>

                    <div className="col-7 py-5">
                        <div className="what-we-do-list-detailed">

                            {
                                this.props.techstack.map(value => {
                                    if(value.id === this.props.whatWeDoActiveClass)
                                        return(
                                        <p className="text-what-we-do-description p-3" key={`${value.id}-description-item`}> {value.technology_description} </p>)
                                    else return (<></>)
                                })
                            }

                        </div>
                    </div>
                </div>
            </>

        )
    }
}

WhatWeDoCard.propTypes = {
    companyDetails: PropTypes.object,
    techstack: PropTypes.array,
    whatWeDoActiveClass: PropTypes.number,
    changeActiveClass: PropTypes.func
}