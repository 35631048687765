import React, { Component } from 'react';
import { connect } from 'react-redux';
import Hero from './components/Hero/Hero';
import Portfolio from './components/Portfolio/Portfolio';
import WhatWeDo from './components/WhatWeDo/WhatWeDo';
import Team from './components/Team/Team';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer';
import WhatWeDoBackground from './components/WhatWeDo/WhatWeDoBackground'
import { getCompanyDetails, getPortfolio, getTechstack, getTeamMembers, onMouseEnterHandler, onMouseLeaveHandler, changeActiveClass, onMouseEnterTeamCard, onMouseLeaveTeamCard } from './redux/actions/actions';
import {
    Link,
    Element,
    Events,
    animateScroll as scroll,
    scroller
} from "react-scroll";

import AOS from 'aos';
import 'aos/dist/aos.css';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: 'hovered-white',
            height: window.innerHeight,
        }
        this.scrollToTop = this.scrollToTop.bind(this);
    }


    listenScrollEvent = e => {
        if (window.scrollY > this.state.height*3 && window.scrollY < this.state.height*4) {
            this.setState({color: 'hovered-blue'})
        } else {
            this.setState({color: 'hovered-white'})
        }
    }

    componentDidMount() {
        AOS.init({
            once: true,
        });
        this.props.getCompanyDetails();
        this.props.getPortfolio();
        this.props.getTechstack();
        this.props.getTeamMembers();
        window.addEventListener('scroll', this.listenScrollEvent);
        }
    
        scrollToTop() {
            scroll.scrollToTop();
        }
        scrollTo() {
            scroller.scrollTo("scroll-to-element", {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart"
        });
        }
        scrollToWithContainer() {
            let goToContainer = new Promise((resolve, reject) => {
            Events.scrollEvent.register("end", () => {
                resolve();
                Events.scrollEvent.remove("end");
            });

            scroller.scrollTo("scroll-container", {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart"
            });
        });

        goToContainer.then(() =>
            scroller.scrollTo("scroll-container-second-element", {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
                containerId: "scroll-container"
            })
        );
    }

    componentWillUnmount() {
        Events.scrollEvent.remove("begin");
        Events.scrollEvent.remove("end");
        window.removeEventListener('scroll', this.listenScrollEvent)
    }

    render(){
        return (
            <div data-spy="scroll" className="container-body h-100 p-0" data-testid='MainComponent'>
                <div className="side-navigation d-none d-md-block" data-testid="sidenav">
                    <ul className="side-navigation-content">
                        <li><Link activeClass="active" className={`hero side-navigation-item ${this.state.color}`} to="hero" spy={true} smooth={true} duration={450} >
                            <span className="number">01</span>
                            <div className="title-onhover">Hero</div>
                        </Link></li>
                        <li><Link activeClass="active" className={`portfolio side-navigation-item ${this.state.color}`} to="portfolio" spy={true} smooth={true} duration={450} >
                            <span className="number">02</span>
                            <div className="title-onhover">Portfolio</div>
                        </Link></li>
                        <li><Link activeClass="active" className={`what-we-do side-navigation-item ${this.state.color}`} to="what-we-do" spy={true} smooth={true} duration={450} >
                            <span className="number">03</span>
                            <div className="title-onhover">What we do</div>
                        </Link></li>
                        <li><Link activeClass="active" className={`team side-navigation-item ${this.state.color}`} to="team" spy={true} smooth={true} duration={400} >
                            <span className="number">04</span>
                            <div className="title-onhover">Team</div>
                        </Link></li>
                        <li><Link activeClass="active-last" className={`contact-us side-navigation-item-last ${this.state.color}`} to="contact-us" spy={true} smooth={true} duration={450} >
                            <span className="number">05</span>
                            <div className="title-onhover">Contact us</div>
                        </Link></li>
                    </ul>
                </div>


                <div className="main">

                    <Element name="hero" id="hero" className="element" >
                        <section>
                            <div className="row h-100 no-gutters m-0 g-0">
                                <div className="col bg-container vh-100" style={{ position:'relative'}}>
                                    <Hero companyDetails = {this.props.companyDetails} />
                                </div>
                            </div>
                        </section>
                    </Element>

                    <Element name="portfolio" className="element" >
                        <section>
                            <div className="row h-100 no-gutters m-0 g-0">
                                <div className="col bg-container portfolio-page" style={{minHeight: "100vh"}}>
                                    <Portfolio
                                        portfolio = {this.props.portfolio}
                                        visible = {this.props.portfolioVisible}
                                        onMouseEnterHandler = {this.props.onMouseEnterHandler}
                                        onMouseLeaveHandler = {this.props.onMouseLeaveHandler}
                                    />
                                </div>
                            </div>
                        </section>
                    </Element>
                        
                    <Element name="what-we-do" className="element" >
                        <section className="pb-3">
                            <div className="row h-100 no-gutters m-0 g-0">
                                <div className="col bg-container" style={{minHeight: "105vh"}}>
                                    <WhatWeDoBackground data-testid='WhatWeDoBackground' />
                                    <WhatWeDo techstack = {this.props.techstack} companyDetails = { this.props.companyDetails }
                                        whatWeDoActiveClass = { this.props.whatWeDoActiveClass } changeActiveClass = { this.props.changeActiveClass }
                                    />
                                </div>
                            </div>
                        </section>
                    </Element>
                        

                    <Element name="team" className="element" >
                        <section className="pb-3">
                            <div className="row h-100 no-gutters m-0 g-0">
                                <div className="col bg-container" style={{minHeight: "105vh"}}>
                                    <Team teamMembers = {this.props.teamMembers}
                                        hovered = {this.props.teamMemberHovered}
                                        onMouseEnterTeamCard = {this.props.onMouseEnterTeamCard}
                                        onMouseLeaveTeamCard = {this.props.onMouseLeaveTeamCard}
                                    />
                                </div>
                            </div>
                        </section>
                    </Element>
                    
                    <Element name="contact-us" id="contact_us" className="element" >
                        <section>
                            <div className="row h-100 no-gutters m-0 g-0">
                                <div className="col bg-container" style={{minHeight: "100vh"}}>
                                    <ContactUs companyDetails = {this.props.companyDetails} />
                                </div>
                            </div>
                        </section>
                    </Element>
                    

                </div>

                <section className="footer">
                    <Footer companyDetails = {this.props.companyDetails} />
                </section>

            </div>

    );
    }
    
}

function mapStateToProps(state) {
    return {
        companyDetails: state.companyDetails,
        portfolio: state.portfolio,
        techstack: state.techstack,
        teamMembers: state.teamMembers,
        portfolioVisible: state.portfolioVisible,
        whatWeDoActiveClass: state.whatWeDoActiveClass,
        teamMemberHovered: state.teamMemberHovered,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCompanyDetails: () => dispatch(getCompanyDetails()),
        getPortfolio: () => dispatch(getPortfolio()),
        getTechstack: () => dispatch(getTechstack()),
        getTeamMembers: () => dispatch(getTeamMembers()),
        onMouseEnterHandler: (id) => dispatch(onMouseEnterHandler(id)),
        onMouseLeaveHandler: (id) => dispatch(onMouseLeaveHandler(id)),
        changeActiveClass: (id) => dispatch(changeActiveClass(id)),
        onMouseEnterTeamCard: (id) => dispatch(onMouseEnterTeamCard(id)),
        onMouseLeaveTeamCard: (id) => dispatch(onMouseLeaveTeamCard(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)