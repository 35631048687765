import React, { Component }  from 'react'
import TeamCard from './TeamCard'
import TeamCardWorkWithUs from './TeamCardWorkWithUs'
import PropTypes from 'prop-types';


export default class Team extends Component {
    render(){
        return(
            <div className="container-fluid h-100 d-flex flex-column" data-testid='TeamComponent'>
                    <div className="row justify-content-center header-wrapper-team m-0 g-0" >
                    <h1 className="text-title page-aligment text-team text-center">Meet the team</h1>
                        <div className="col-9" style={{ minHeight: '70vh' }}>
                            <div className='row justify-content-center m-0 g-0'>
                                {
                                Object.entries(this.props.teamMembers).map(([key, value]) => 
                                <>
                                    <TeamCard
                                        data-testid='TeamCard'
                                        key= { key }
                                        id = { value.id }
                                        full_name = { value.full_name }
                                        picture = { value.picture }
                                        position = { value.position }
                                        linkedin = { value.linkedin }
                                        color = { value.color }
                                        hovered = { this.props.hovered }
                                        onMouseEnterTeamCard = {this.props.onMouseEnterTeamCard}
                                        onMouseLeaveTeamCard = {this.props.onMouseLeaveTeamCard}
                                    />
                                </>
                                )
                                }
                                    <TeamCardWorkWithUs
                                        data-testid='TeamCard'
                                        key= {6}
                                        id = {6}
                                        full_name = 'Work with us'
                                        picture = '/media/pictures/default.webp'
                                        position = ''
                                        linkedin = {null}
                                        color = 'bg-grey'
                                        hovered = { this.props.hovered }
                                        onMouseEnterTeamCard = {this.props.onMouseEnterTeamCard}
                                        onMouseLeaveTeamCard = {this.props.onMouseLeaveTeamCard}
                                    />
                            </div>
                        </div>
                    </div>
                </div>

        )
    }
}

Team.propTypes = {
    teamMembers: PropTypes.array
}