import React, { Component }  from "react";
import DragDropinput from "./DragDropinput"
import DjangoCSRFToken from 'django-react-csrftoken'
import axios from 'axios';


export default class TeamCardWorkWithUsForm extends Component {
    constructor(){
        super();
        this.state={
            full_name:'',
            email:'',
            phone:'',
            position:'',
            message:'',
            image: null,
            cv:null
        }
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.fileHandler=this.fileHandler.bind(this);

    }

    fileHandler(childData){
        const acceptedFileItems = Object.keys(childData).map((fileName) => {
            this.setState({cv:childData[fileName].file})
            console.log('child data', childData[fileName].file)
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let form_data = new FormData();
        form_data.append('full_name', this.state.full_name);
        form_data.append('email', this.state.email);
        form_data.append('phone', this.state.phone);
        form_data.append('position', this.state.position);
        form_data.append('message', this.state.message);
        form_data.append('cv', this.state.cv);


        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
        axios.defaults.xsrfCookieName = "csrftoken";

        let url = 'https://onsightteam.com/api/post-team-form/';
        axios.post(url, form_data, {
            headers: {
                'content-type': 'multipart/form-data'
        }
        })
            .then(res => {
                console.log(res.data);
            })
            .catch(err => console.log(err.response))
    };

    render(){
        return( 

            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <form id="teamForm" name="teamForm" className="teamForm px-5 py-5" data-aos="fade-in" data-aos-duration="1300" data-testid='ContactUsForm'>
                        <DjangoCSRFToken />
                        <div className="row m-0 g-0 w-100 mb-4">
                            <div className="col-11 d-flex justify-content-center">
                                <h2 className="text-team-member-card-name"><b>Start Your Career Journey</b></h2>
                            </div>
                            <div className="col-1 d-flex justify-content-end">
                            <button type="button" data-dismiss="modal" className="close" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black;" className="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="text-md text-black float-left" htmlFor="fullName">Full Name</label>
                            <input value={this.state.full_name} id="full_name"  name="full_name" data-testid='fullName' 
                                onChange={this.handleChange} type="text" className="control-team" required />
                        </div>
                        <div className="form-group">
                            <label className="text-md text-black float-left" htmlFor="email">Email</label>
                            <input value={this.state.email} name="email" data-testid='email' id='email'
                                onChange={this.handleChange} type="email" className="control-team" required />
                        </div>
                        <div className="form-group">                            
                            <label className="text-md text-black float-left" htmlFor="phone">Phone number</label>
                            <input value={this.state.phone} name="phone" data-testid='subject' id='phone'
                                onChange={this.handleChange} type="text" className="control-team" required />
                        </div>
                        <div className="form-group">
                            <label className="text-md text-black float-left" htmlFor="position">Prefered Position</label>
                            <input value={this.state.position} name="position" data-testid='position' id='position'
                                onChange={this.handleChange} type="text" className="control-team" required />
                        </div>
                        <div className="form-group">
                            <label className="text-md text-black float-left" htmlFor="message">Message</label>
                            <textarea value={this.state.message} name="message" data-testid='message' id='message'
                                onChange={this.handleChange} type="text" className="control-team-message" />
                        </div>
                        <DragDropinput getFile={this.fileHandler} />
                        <div className="btn-container">
                            <button type="submit" data-dismiss="modal" onClick={this.handleSubmit} data-testid='submitButton' className="btn btn-contact w-100">Submit</button>
                        </div>
                    </form>

                </div>
            </div>
        )
    }
}