import axios from 'axios'
import {
    GET_COMPANY_DETAILS,
    GET_PORTFOLIO,
    GET_TECHSTACK,
    GET_TEAM_MEMBERS,
    PORTFOLIO_HOVER_SHOW,
    PORTFOLIO_HOVER_HIDE,
    CHANGE_ACTIVE_CLASS,
    TEAM_CARD_HOVER_SHOW,
    TEAM_CARD_HOVER_HIDE
}  from '../actions/actionTypes'


export function changeActiveClass(id) {
    return {
        type: CHANGE_ACTIVE_CLASS,
        payload: id,
    }
}

export function onMouseEnterHandler(id) {
    return {
        type: PORTFOLIO_HOVER_SHOW,
        payload: id,
        data: true,
    }
}

export function onMouseLeaveHandler(id) {
    return {
        type: PORTFOLIO_HOVER_HIDE,
        payload: id,
        data: false,
    }
}

export function onMouseEnterTeamCard(id) {
    return {
        type: TEAM_CARD_HOVER_SHOW,
        payload: id,
        data: true,
    }
}

export function onMouseLeaveTeamCard(id) {
    return {
        type: TEAM_CARD_HOVER_HIDE,
        payload: id,
        data: false,
    }
}

export const getCompanyDetails = () => async dispatch => {
    
    try{
        const data = await axios.get('/api/get-company-details/')

        dispatch( {
            type: GET_COMPANY_DETAILS,
            payload: data.data,
        })
    }
    catch(e){
        dispatch( {
            type: 'ERROR',
            payload: console.log(e),
        })
    }
}

export const getPortfolio = () => async dispatch => {
    
    try{
        const data = await axios.get('/api/get-portfolio/')
        // hidding avdocs
        data.data.splice(5,1)
        dispatch( {
            type: GET_PORTFOLIO,
            payload: data.data,
        })
    }
    catch(e){
        dispatch( {
            type: 'ERROR',
            payload: console.log(e),
        })
    }
}

export const getTechstack = () => async dispatch => {
    
    try{
        const data = await axios.get('/api/get-techstack/')

        dispatch( {
            type: GET_TECHSTACK,
            payload: data.data,
        })
    }
    catch(e){
        dispatch( {
            type: 'ERROR',
            payload: console.log(e),
        })
    }
}

export const getTeamMembers = () => async dispatch => {
    
    try{
        const data = await axios.get('/api/get-team-members/')

        dispatch( {
            type: GET_TEAM_MEMBERS,
            payload: data.data,
        })
    }
    catch(e){
        dispatch( {
            type: 'ERROR',
            payload: console.log(e),
        })
    }
}