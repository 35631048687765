import React, { Component }  from 'react';
import 'bootstrap';
import PropTypes from 'prop-types';


export default class PortfolioModal extends Component {

    render(){
        return(
            <div className={`modal fade bd-example-modal-lg-${this.props.id}`} id="modal1" tabIndex="-1" data-backdrop="true" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-testid='PortfolioModal'>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content modal-body">
                        <div className="row d-flex justify-content-end">
                            <div className='col'>
                                <button type="button" data-dismiss="modal" className="close" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" className="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="row px-md-2 py-md-2 px-0 py-0">

                            {
                                this.props.logo &&
                                    <div className="d-flex col-lg-2 col-md-3 col-4 justify-content-center">
                                        <img src={ this.props.logo } alt="logo_small" className="small-logo-modal py-3 img-fluid" />
                                    </div>
                            }

                            <div className="col-lg-10 col-md-9 col-8">
                                <div className="row ">
                                    <h1 className="title-portfolio-modal my-2">{ this.props.title } </h1>

                            {
                                this.props.current_techstack.map(item =>
                                <p id={ this.props.style_name } key={ item } className="my-2 text-center rounded-badge-modal"> { item.toUpperCase() } </p>
                            ) }

                            {
                                this.props.website &&
                                <a className="my-2 text-description-modal link" target="_blank" rel="noopener noreferrer" href={ this.props.website }> { this.props.website } </a>
                            }

                                </div>
                            </div>

                        </div>

                        <div className="row row-cols-1 row-cols-md-3s px-0 py-0 d-flex justify-content-center">
                            <p className="w-100 text-description-modal"> { this.props.full_description } </p>
                        </div>

                        { this.props.screenshot &&
                            <div className="row row-cols-1 row-cols-md-3 px-md-2 py-md-2 px-0 py-0 d-flex justify-content-center">
                                <img src={ this.props.screenshot } alt="big_picture" className="modal-image img-fluid" />
                            </div>
                        }

                        <div className="row row-cols-1 row-cols-md-3 px-md-2 py-md-2 px-0 py-0">
                            <h5 className="subtitle-portfolio-modal">Our Work</h5>
                        </div>

                        <div className="row row-cols-1 row-cols-md-3 px-md-2 py-md-2 px-0 py-0">
                            <div className="col-11 w-100">
                                <p className="text-description-modal"> { this.props.company_work } </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PortfolioModal.propTypes = {
    id : PropTypes.number,
    title: PropTypes.string,
    short_description: PropTypes.string,
    full_description: PropTypes.string,
    company_work: PropTypes.string,
    screenshot: PropTypes.string,
    website: PropTypes.string,
    url: PropTypes.string,
    logo: PropTypes.string,
    card_background: PropTypes.string,
    style_name: PropTypes.string,
    current_techstack: PropTypes.array,
}
