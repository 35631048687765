import React, { Component }  from 'react'
import PortfolioModal from './PortfolioModal';
import PortfolioCardNonModal from './PortfolioCardNonModal'; 
import PropTypes from 'prop-types';


export default class PortfolioCard extends Component {

    modalOpen = (id) => {
        window.$(".bd-example-modal-lg-"+id).modal('show');
    }

    render(){
        return(
                <div className= {`col-${this.props.wrapperColNumber} d-flex justify-content-center portfolio-wrapper`}
                    data-testid="PortfolioCard"
                    onMouseEnter={ () => this.props.onMouseEnterHandler(this.props.id)} 
                    onMouseLeave={ () => this.props.onMouseLeaveHandler(this.props.id)}
                >
                <PortfolioModal
                    data-testid="PortfolioModal"
                    key={ this.props.id }
                    id={ this.props.id }
                    title={ this.props.title }
                    short_description={ this.props.short_description }
                    full_description={ this.props.full_description }
                    company_work={ this.props.company_work }
                    screenshot={ this.props.screenshot }
                    website={ this.props.website }
                    url={ this.props.url }
                    logo={ this.props.logo }
                    cart_background={ this.props.cart_background }
                    style_name={ this.props.style_name }
                    current_techstack={ this.props.current_techstack }
                    technologies={ this.props.technologies }
                />

                {
                this.props.visible[this.props.id].visible
                // content after hover
                    ?
                        <div>
                            {
                            this.props.id === 6 || this.props.id === 7 ?
                                    <PortfolioCardNonModal 
                                        id={ this.props.id }
                                        cardClass={ this.props.cardClass }
                                        title={ this.props.title }
                                        short_description={ this.props.short_description }
                                        logo={ this.props.logo }
                                        url={ this.props.url }
                                    />
                                    :
                                    <div className={`row h-100 m-0 g-0 card${this.props.cardClass} d-flex text-center justify-content-center align-items-center card-wrapper-animation `} data-testid='cardAfterHover' onClick={() => this.modalOpen(this.props.id)}>
                                    <div className="col-10 p-0">
                                        {
                                            this.props.logo &&
                                                <img src={ this.props.logo } alt="logo_small" className="pt-3 small-logo-hovered img-fluid" data-testid='logoAfterHover' />
                                        }
                                        <p className="portfolio-card-title text-white text-center my-4 animated-content h1"> { this.props.title } </p>
                                        <p className="portfolio-card-description portfolio-card-description-color text-center m-0 h6"> { this.props.short_description } </p>
                                        <div className="my-3 btn btn-portfolio-card" onClick={() => this.modalOpen(this.props.id)} data-testid='buttonAfterHover'>See more</div>
                                    </div>
                                </div>
                            }
                        </div>
                    :
                        <div className={`row h-100 m-0 g-0 card-wrapper ${this.props.cardClass}`} data-testid='cardBeforeHover'>
                            <div className="d-flex justify-content-center text-center align-items-center animated-content">
                                <div className="col-10 p-0">
                                {
                                    this.props.logo &&
                                        <img src={ this.props.logo } alt="logo_small" className="small-logo pt-3 img-fluid" data-testid='logoBeforeHover'/>
                                }
                                    <p className="portfolio-card-title portfolio-card-description-color text-center my-4 animated-content"> { this.props.title } </p>
                                    <p className="portfolio-card-description text-white text-center my-1"> { this.props.short_description } </p>
                                </div>
                            </div>
                        </div>
                }

                </div>
        )
    }
}

PortfolioCard.propTypes = {
    visible: PropTypes.arrayOf(PropTypes.object),
    id : PropTypes.number,
    title: PropTypes.string,
    short_description: PropTypes.string,
    full_description: PropTypes.string,
    company_work: PropTypes.string,
    screenshot: PropTypes.string,
    website: PropTypes.string,
    url: PropTypes.string,
    logo: PropTypes.string,
    card_background: PropTypes.string,
    style_name: PropTypes.string,
    current_techstack: PropTypes.array,
    onMouseEnterHandler : PropTypes.func,
    onMouseLeaveHandler : PropTypes.func,
}