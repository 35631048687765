import Particles from "react-tsparticles";
import React, { Component }  from 'react'


export default class WhatWeDoBackground extends Component {
    constructor(props) {
    super(props);

    this.particlesInit = this.particlesInit.bind(this);
    this.particlesLoaded = this.particlesLoaded.bind(this);
}

particlesInit() {
}

particlesLoaded() {
}

    render() {
    return (
        <Particles
            id="tsparticles"
            init={this.particlesInit}
            loaded={this.particlesLoaded}
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%'
            }}

            options={{
            background: {
                color: {
                value: "#1E3051",
                },
            },
            fpsLimit: 60,
            interactivity: {
                detectsOn: "canvas",
                events: {
                onClick: {
                    enable: true,
                    mode: "push",
                },
                onHover: {
                    enable: true,
                    mode: "repulse",
                },
                resize: true,
                },
                modes: {
                bubble: {
                    distance: 500,
                    duration: 2,
                    opacity: 0.8,
                    size: 40,
                },
                push: {
                    quantity: 3,
                },
                repulse: {
                    distance: 200,
                    duration: 0.4,
                },
                },
            },
            particles: {
                color: {
                value: "#ffffff",
                },
                links: {
                color: "#ffffff",
                distance: 120,
                enable: true,
                opacity: 0.5,
                width: 1,
                },
                collisions: {
                enable: true,
                },
                move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: false,
                speed: 2,
                straight: false,
                },
                number: {
                density: {
                    enable: true,
                    value_area: 500,
                },
                value: 50,
                },
                opacity: {
                value: 0.5,
                },
                shape: {
                type: "circle",
                },
                size: {
                random: false,
                value: 4,
                },
            },
            detectRetina: true,
            }}
        />        
    );
    }
}