import React, { Component }  from 'react'
import HeroBackground from './HeroBackground'
import PropTypes from 'prop-types';

export default class Hero extends Component {
    render(){
        return(
            <div className="container-fluid h-100 w-100 p-0 d-flex flex-column" id="background" data-testid="HeroComponent">
                <HeroBackground data-testid="BackgroundDiv" />
                <div className="row align-items-center justify-content-center flex-grow-1">
                    <div className="col-11 col-md-10 col-lg-8 col-xl-6"
                        style={{  position: 'relative', zIndex: 100 }}>
                        <h1 className="display-3 text-light text-center mb-lg-5"> { this.props.companyDetails.main_phrase } </h1>
                        <h3 className="h4 text-light text-center mb-5 pb-5 mb-sm-0 pb-sm-0"> { this.props.companyDetails.detail_text } </h3>
                        <div className="row justify-content-center">
                            <a href="#contact_us" data-aos="fade-up" data-aos-duration='1200' className="col-12 col-md-6 btn btn-lg btn-light mt-5 mt-sm-5">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Hero.propTypes = {
    main_phrase: PropTypes.string,
    detail_text: PropTypes.string,
}