import React, { Component }  from "react";
import PropTypes from "prop-types";


export default class TeamCard extends Component {
    render(){
        return( 
            <div className="col-lg-3 col-md-3 col-sm-12 mx-4 py-5 h-100 d-flex justify-content-center">
                <div id="team_card" className="team-card" data-testid="TeamCard"
                    onMouseEnter={ () => this.props.onMouseEnterTeamCard(this.props.id)} 
                    onMouseLeave={ () => this.props.onMouseLeaveTeamCard(this.props.id)}
                >
                    <div className="row m-0 g-0 d-flex justify-content-center align-items-center team-wrapper">
                        {
                            this.props.hovered[this.props.id].hovered ?
                            <div id={ this.props.color } className="emoji-background-hovered"></div>
                            :
                            <div id={ this.props.color } className="emoji-background"></div>

                        }
                        <div id="team_img" className="team-img img-fluid" data-testid="teamPicture" 
                            style={{ 
                                backgroundImage: "url(" + this.props.picture + ")", 
                                backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center",
                                position: "relative", zIndex: 100,
                            }}>
                        </div>
                    </div>
                    
                    <div className="row m-0 g-0 mb-4 card-content">
                        <h1 className="text-team-member-card-name"> <b> { this.props.full_name } </b> </h1>
                        <div className="text-team-member-card-position mb-2"> { this.props.position }{"\n"} </div>

                        { this.props.linkedin
                                    ? 
                                    <a style={{width:'fit-content'}} href={ this.props.linkedin }><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style={{color: '#1E3051', width:'28px'}} className="bi bi-linkedin" viewBox="0 0 16 16">
                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                        </svg>
                                    </a>
                                : 
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style={{color: 'gray', width:'28px'}} className="bi bi-linkedin" viewBox="0 0 16 16">
                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                </svg>
                        }

                    </div>

                </div>
            </div>
        )
    }
}

TeamCard.propTypes = {
    id: PropTypes.number,
    full_name: PropTypes.string,
    picture: PropTypes.string,
    position: PropTypes.string,
    linkedin: PropTypes.string
}