import React, { Component }  from 'react'
import PortfolioCard from './PortfolioCard';
import PropTypes from 'prop-types';


export default class Portfolio extends Component {

    constructor(props) {
        super(props);
        this.state = { windowWidth: window.innerWidth };
    }

    render(){
        const { windowWidth } = this.state; 
        return(
            <div className="d-flex flex-column min-vh-100 px-5" data-testid='PortfolioComponent'>

                <div className="py-5">
                    <div className="col">
                        <h2 className="text-white text-center display-5">Our Portfolio</h2>
                    </div>
                </div>

                <div id="carouselExampleIndicators" className="flex-grow-1 row d-flex align-items-center justify-content-center carousel slide" data-interval="false" data-testid="carousel" data-aos="fade-up" data-aos-duration='1200'>
                    <div className="d-flex align-items-center carousel-inner carousel-wrapper">

                    { windowWidth > 567
                        ? 
                            <>
                                <div className="carousel-item active" data-testid="carouselItem">
                                    <div className="d-flex flex-row justify-content-center">
                                    {
                                        Object.entries(this.props.portfolio.slice(0, 3)).map(([key, value]) => 
                                            <PortfolioCard
                                                data-testid="carouselCard"
                                                wrapperColNumber = '4'
                                                cardClass = ''
                                                key= { key }
                                                id = { value.id-1 }
                                                title={ value.title }
                                                short_description={ value.short_description }
                                                full_description={ value.full_description }
                                                company_work={ value.company_work }
                                                screenshot={ value.screenshot }
                                                website={ value.website }
                                                url={ value.url }
                                                logo={ value.logo }
                                                card_background={ value.card_background }
                                                style_name={ value.style_name }
                                                current_techstack={ value.technologies }
                                                visible = {this.props.visible}
                                                onMouseEnterHandler = {this.props.onMouseEnterHandler}
                                                onMouseLeaveHandler = {this.props.onMouseLeaveHandler}
                                            />
                                        )
                                    }
                                    </div>
                                </div>

                                <div className="carousel-item" data-testid="carouselItem">
                                    <div className="d-flex flex-row justify-content-center">
                                    {
                                        Object.entries(this.props.portfolio.slice(3, 6)).map(([key, value]) => 
                                            <PortfolioCard
                                            data-testid="carouselCard"
                                                wrapperColNumber = '4'
                                                cardClass = ''
                                                key= { key }
                                                id = { value.id-1 }
                                                title={ value.title }
                                                short_description={ value.short_description }
                                                full_description={ value.full_description }
                                                company_work={ value.company_work }
                                                screenshot={ value.screenshot }
                                                website={ value.website }
                                                url={ value.url }
                                                logo={ value.logo }
                                                card_background={ value.card_background }
                                                style_name={ value.style_name }
                                                current_techstack={ value.technologies }
                                                visible = {this.props.visible}
                                                onMouseEnterHandler = {this.props.onMouseEnterHandler}
                                                onMouseLeaveHandler = {this.props.onMouseLeaveHandler}
                                            />
                                        )
                                    }
                                    </div>
                                </div>
                                {/* hidding avdocs  */}
                                <div className="carousel-item" data-testid="carouselItem">
                                    <div className="d-flex flex-row justify-content-center">
                                    {
                                        Object.entries(this.props.portfolio.slice(6)).map(([key, value]) => 
                                            <PortfolioCard
                                            data-testid="carouselCard"
                                                wrapperColNumber = '4'
                                                cardClass = ''
                                                key= { key }
                                                id = { value.id-1 }
                                                title={ value.title }
                                                short_description={ value.short_description }
                                                full_description={ value.full_description }
                                                company_work={ value.company_work }
                                                screenshot={ value.screenshot }
                                                website={ value.website }
                                                url={ value.url }
                                                logo={ value.logo }
                                                card_background={ value.card_background }
                                                style_name={ value.style_name }
                                                current_techstack={ value.technologies }
                                                visible = {this.props.visible}
                                                onMouseEnterHandler = {this.props.onMouseEnterHandler}
                                                onMouseLeaveHandler = {this.props.onMouseLeaveHandler}
                                            />
                                        )
                                    }
                                    </div>
                                </div>
                            </>
                        :  
                            <>
                                <div className="carousel-item active" data-testid="carouselItem">
                                    <div className="d-flex flex-row justify-content-center">
                                    {
                                        Object.entries(this.props.portfolio.slice(0, 1)).map(([key, value]) => 
                                            <PortfolioCard
                                                data-testid="carouselCard"
                                                wrapperColNumber = '12'
                                                cardClass = '-mobile'
                                                key= { key }
                                                id = { value.id-1 }
                                                title={ value.title }
                                                short_description={ value.short_description }
                                                full_description={ value.full_description }
                                                company_work={ value.company_work }
                                                screenshot={ value.screenshot }
                                                website={ value.website }
                                                url={ value.url }
                                                logo={ value.logo }
                                                card_background={ value.card_background }
                                                style_name={ value.style_name }
                                                current_techstack={ value.technologies }
                                                visible = {this.props.visible}
                                                onMouseEnterHandler = {this.props.onMouseEnterHandler}
                                                onMouseLeaveHandler = {this.props.onMouseLeaveHandler}
                                            />
                                        )
                                    }
                                    </div>
                                </div>

                                    {
                                        Object.entries(this.props.portfolio.slice(1)).map(([key, value]) => 
                                        <div className="carousel-item" data-testid="carouselItem">
                                            <div className="d-flex flex-row justify-content-center">
                                                <PortfolioCard
                                                    data-testid="carouselCard"
                                                    wrapperColNumber = '12'
                                                    cardClass = '-mobile'
                                                    key= { key }
                                                    id = { value.id-1 }
                                                    title={ value.title }
                                                    short_description={ value.short_description }
                                                    full_description={ value.full_description }
                                                    company_work={ value.company_work }
                                                    screenshot={ value.screenshot }
                                                    website={ value.website }
                                                    url={ value.url }
                                                    logo={ value.logo }
                                                    card_background={ value.card_background }
                                                    style_name={ value.style_name }
                                                    current_techstack={ value.technologies }
                                                    visible = {this.props.visible}
                                                    onMouseEnterHandler = {this.props.onMouseEnterHandler}
                                                    onMouseLeaveHandler = {this.props.onMouseLeaveHandler}
                                                />
                                                </div>
                                        </div>
                                        )
                                    }
                            </>
                        }

                    </div>
                
                </div>

                <div className="controlsBlock">
                    <a className="carousel-control-prev w-25" href="#carouselExampleIndicators" role="button" data-slide="prev" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="25" viewBox="0 0 50 25">
                            <g fill="none" fillRule="evenodd">
                                <g fill="#27889E" fillRule="nonzero">
                                    <g>
                                        <path d="M159.601.316c.312-.374.869-.422 1.244-.11.375.311.423.867.11 1.241l-8.193 9.784h46.513c.4 0 .725.325.725.726 0 .4-.324.724-.725.724h-46.754l8.865 10.509c.289.34.272.836-.02 1.158l-.086.084c-.166.138-.367.206-.569.206-.249 0-.5-.106-.674-.315l-9.829-11.653c-.277-.326-.277-.804-.003-1.133z" transform="translate(-620.000000, -882.000000) translate(720.000000, 894.318841) scale(-1, 1) translate(-720.000000, -894.318841) translate(620.000000, 882.000000) translate(175.000000, 12.318841) scale(-1, 1) translate(-175.000000, -12.318841)"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                    <a className="carousel-control-next w-25" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="25" viewBox="0 0 50 25">
                            <g fill="none" fillRule="evenodd">
                                <g fill="#27889E" fillRule="nonzero">
                                    <g>
                                        <path d="M9.601.316c.312-.374.869-.422 1.244-.11.375.311.423.867.11 1.241l-8.193 9.784h46.513c.4 0 .725.325.725.726 0 .4-.324.724-.725.724H2.521l8.865 10.509c.289.34.272.836-.02 1.158l-.086.084c-.166.138-.367.206-.569.206-.249 0-.5-.106-.674-.315L.208 12.67c-.277-.326-.277-.804-.003-1.133z" transform="translate(-770.000000, -882.000000) translate(720.000000, 894.318841) scale(-1, 1) translate(-720.000000, -894.318841) translate(620.000000, 882.000000)"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>

                <div className="d-flex justify-content-center py-5" data-testid="DownloadButton">
                {/* col-12 col-md-6 col-xl-4 btn btn-lg btn-light */}
                    <a href={'media/documents/Portfolio.pdf'} download="Portfolio.pdf" className="btn btn-download" target="_blank" rel="noopener noreferrer"> Download Portfolio </a>
                </div>

            </div>
        )
    }
}

Portfolio.propTypes = {
    wrapperColNumber : PropTypes.string,
    cardClass : PropTypes.string,
    id : PropTypes.number,
    title: PropTypes.string,
    short_description: PropTypes.string,
    full_description: PropTypes.string,
    company_work: PropTypes.string,
    screenshot: PropTypes.string,
    website: PropTypes.string,
    url: PropTypes.string,
    logo: PropTypes.string,
    card_background: PropTypes.string,
    style_name: PropTypes.string,
    current_techstack: PropTypes.array,
    visible :  PropTypes.array,
    onMouseEnterHandler : PropTypes.func,
    onMouseLeaveHandler : PropTypes.func,
}